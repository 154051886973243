import { FC } from "react";
import { getPage } from "../appState/getPage";
import { getCurrentRequestPath } from "../data/onapp/getCurrentRequestPath";
import { Page } from "./Page.react";
import { dump } from "../tracy/dump";
import { IAppState, IHttpPath, IHttpQueryParams, IPage } from "../types";

export interface IMainProps {
  appState: IAppState;
}

export const Main: FC<IMainProps> = ({ appState }: IMainProps) => {
  const path: IHttpPath = getCurrentRequestPath();
  const queryParams: IHttpQueryParams = {};
  const page: IPage = getPage(appState, path, queryParams);
  dump("RENDER_SINGLE_PAGE_WITH_PARAMS", page);
  return <Page pg={page} />;
};
