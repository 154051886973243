import { IAppState } from "../../../types";

export const mockAppState: any = {
  lang: "eng",
  components: [],
  pages: [
    {
      sid: "dfsdfdsfsdfsdfsdfds",
      title: "Website not exists.",
      lang: "eng",
      pagetype: "html_page_not_found",
      components: [
        {
          data: {
            htmlTagName: "div",
            htmlProps: {
              className: "component-class-name-fsdfsdfdsfdsd",
            },
            children: [
              {
                children: "One row content bold",
                key: "sdfs",
                htmlTagName: "b",
              },
              {
                children: "One row content normal",
                key: "sdfsd",
                htmlTagName: "div",
              },
            ],
            key: "asdas",
          },
        },
      ],
    },
  ],
};

// export const mockAppState: IAppState = {
//   lang: "eng",
//   pages: [
//     {
//       sid: "dfsdfdsfsdfsdfsdfds",
//       title: "Website not exists.",
//       lang: "eng",
//       pagetype: "html_page_not_found",
//       components: [
//         {
//           sid: "56df4f56sd4f56s",
//           cmp_type: "content_row_cmp",
//           data: {
//             rows: [
//               {
//                 html: "<h1>Website not exists.</h1>",
//                 align: "center",
//               },
//               {
//                 html: "Would you like to create it?",
//                 align: "center",
//               },
//               {
//                 html: "<button>Create website now</button>",
//                 align: "center",
//               },
//             ],
//           },
//         },
//       ],
//     },
//   ],
//   components: [],
// };
