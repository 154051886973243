import React, { FC } from "react";
import { Component } from "./Component.react";
import { IComponent, IPage } from "../types";
import { setHtmlTitle } from "./setHtmlTitle";
import { coreRenderCmp } from "../corecmp/coreRenderCmp";

export interface IPageProps {
  pg: IPage;
}

export const Page: FC<IPageProps> = ({ pg }: IPageProps) => {
  setHtmlTitle(pg.title);
  return (
    <div id="page">
      {/* {render} */}
      PG START
      {pg.components.map((cmp: IComponent) => (
        <React.Fragment key={cmp.sid}>{coreRenderCmp(cmp)}</React.Fragment>
      ))}
    </div>
  );
};
