import { IElementSpec } from "./coreRenderCmp";

export const validateCmpDataElement = (cmpData: IElementSpec | string) => {
  if (typeof cmpData === "string") {
    return true;
  } else {
    if (typeof cmpData.htmlTagName !== "string") {
      console.error(cmpData);
      throw new Error("IElementSpec must has htmlTagName as string.");
    }
    return true;
  }
};
