import { IAppState, IHttpPath, IHttpQueryParams, IPage } from "../types";

// export interface IHttpRequest {
//   path: string;
//   queryParameters: IHttpQueryParams;
// }

export const getPage = (appState: IAppState, path: IHttpPath, queryParameters: IHttpQueryParams): IPage => {
  const pg: IPage | undefined = appState.pages.find((pg: IPage) => {
    return pg.route?.path === path;
  });
  if (pg) {
    return pg;
  } else {
    return appState.pages.find((pg: IPage) => {
      return pg.pagetype === "html_page_not_found";
    }) as IPage;
  }
  // if (!pg) {
  // }

  // console.log("APP_STATE");
  // console.log(appState);
  // return {} as IPage;

  // // return {
  // //   sid: "sdsgdsfgvdfsgfg",
  // //   route: {
  // //     lang: "eng",
  // //     path: "",
  // //   },
  // //   routes: [
  // //     {
  // //       lang: "eng",
  // //       path: "",
  // //     },
  // //   ],
  // //   title: "Page not found",
  // //   components: [],
  // //   lang: appState.lang,
  // // };
};
