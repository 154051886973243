import React, { useEffect, useState } from "react";
import { neverHappen } from "../tracy/dump";
import { Main } from "./Main.react";
import { dump } from "../tracy/dump";
import { IAppState } from "../types";
import { appGetAppState } from "../data/onapp/appGetAppState";

function App() {
  const [appState, setAppState] = useState<IAppState>();

  useEffect(() => {
    // GET APP DATA
    appGetAppState()
      .then((appDateState: IAppState) => {
        dump("GOT APP STATE TO APP");
        dump(appDateState);
        setAppState(appDateState);
      })
      .catch((err) => {
        neverHappen(err);
      });
  }, []);

  /**
   * If App state
   */
  if (!appState) {
    return (
      <>
        <div id="loader">1</div>
      </>
    );
  } else {
    dump("RENDER APP WITH STATE");

    return <Main appState={appState} />;
  }
}

export default App;
