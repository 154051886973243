import React from "react";
import { IComponent } from "../types";
import { validateCmpDataElement } from "./cmp.utils";

export interface IElementSpec {
  htmlTagName: string;
  htmlProps?: string[];
  children: string;
  key: string;
}

export const getElement: any = (el: IElementSpec | IElementSpec[] | string | string[]) => {
  //validateCmpDataElement(el);
  if (typeof el === "string") {
    return React.createElement("div", null, el);
  } else {
    if (Array.isArray(el)) {
      return el.map((elOne: IElementSpec | string) => {
        return <React.Fragment key={Date.now().toString()}>{getElement(elOne)}</React.Fragment>;
      });
    } else {
      return React.createElement(el.htmlTagName, el.htmlProps ?? null, typeof el.children === "string" ? el.children : "xxxx");
    }
  }
};

export const renderElement = (el: IElementSpec | string) => {
  validateCmpDataElement(el);
  if (typeof el === "string") {
    return <React.Fragment key={el ?? "_"}>{el}</React.Fragment>;
    //return <div>AAA</div>;
  } else {
    //return <div>AAA</div>;
    return <React.Fragment key={"fdf"}>{React.createElement(el.htmlTagName, el.htmlProps ?? null, getElement(el.children))}</React.Fragment>;
  }
};

export const renderElements = (els: any) => {
  return els.map((el: IElementSpec) => {
    return renderElement(el);
  });
};

export const renderData = (data: any) => {
  if (Array.isArray(data)) {
    return renderElements(data);
  } else {
    return renderElement(data);
  }
};

export const coreRenderCmp = (cmp: IComponent) => {
  return <>{renderData(cmp.data)}</>;
};
