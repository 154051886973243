//import { URL, Url } from "url"

import { IHttpQueryParams } from "../../types";

export const getCurrentRequestPath = (): string => {
  return getPath(window.location.href);
};

export const getPath = (urlString: string): string => {
  const urlObject: URL = new URL(urlString);
  return urlObject.pathname;
};

export const getCurrentRequestQueryArguments = (): IHttpQueryParams => {
  const urlObject: URL = new URL(window.location.href);
  console.log("getCurrentRequestQueryArguments");
  console.log(urlObject);

  return {};
};
